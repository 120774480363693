import React, {
	useContext,
	useCallback,
	useEffect,
	useState,
} from "react";
import "./EmailChecker.scss";
import {
	Box,
	Grid,
	Paper,
	Typography
} from "@mui/material";
import { DataContext } from "../../App";
import useFetchData from "../Shared/FetchData/FetchData";
import { PageHeading } from "../Shared/PageHeading/PageHeading";
import {
	getFormattedStartTime,
	getFormattedEndTime
} from "../../utils/date.js";
import { LoadableContent } from "../Shared/LoadableContent/LoadableContent";

export default function EmailChecker() {

	const context = useContext(DataContext);

	const todayStart = new Date();
	todayStart.setHours(todayStart.getHours() - 24);
	const todayParams = {
		start_date: getFormattedStartTime(todayStart),
		end_date: getFormattedEndTime(todayStart),
	};

	const weekStart = new Date();
	weekStart.setDate(weekStart.getDate() - 7);
	const weekParams = {
		start_date: getFormattedStartTime(weekStart),
		end_date: getFormattedEndTime(new Date()),
	};

	const monthStart = new Date();
	monthStart.setDate(monthStart.getDate() - 30);
	const monthParams = {
		start_date: getFormattedStartTime(monthStart),
		end_date: getFormattedEndTime(new Date()),
	};

	const todayDeliveredEvents = useFetchData(useCallback(() =>
		context.dataProvider.getDeliveredEvents(todayParams),
	[context.dataProvider]));

	const weekDeliveredEvents = useFetchData(useCallback(() =>
		context.dataProvider.getDeliveredEvents(weekParams),
	[context.dataProvider]));

	const monthDeliveredEvents = useFetchData(useCallback(() =>
		context.dataProvider.getDeliveredEvents(monthParams),
	[context.dataProvider]));

	const page_data = {
		deliveredToday: todayDeliveredEvents,
		deliveredWeek: weekDeliveredEvents,
		deliveredMonth: monthDeliveredEvents,
	};

	const [state, setState] = useState({
		deliveredToday: 0,
		deliveredWeek: 0,
		deliveredMonth: 0,
		isLoading: true,
	});
	
	const getEventCount = (results) => {
		const events = results ? results.events : [];
		return events ? events.length : 0;
	};

	useEffect(() => {
		setState({
			deliveredToday: getEventCount(page_data.deliveredToday.results),
			deliveredWeek: getEventCount(page_data.deliveredWeek.results),
			deliveredMonth: getEventCount(page_data.deliveredMonth.results),
			isLoading: page_data.deliveredToday.isLoading || page_data.deliveredWeek.isLoading || page_data.deliveredMonth.isLoading,
		});
	}, [page_data.deliveredToday.results,
		page_data.deliveredWeek.results,
		page_data.deliveredMonth.results,
		page_data.deliveredToday.isLoading,
		page_data.deliveredWeek.isLoading,
		page_data.deliveredMonth.isLoading]
	);

	return (
		<Box id="email-checker">
			<PageHeading pageTitle={"Email Checker"}>
			</PageHeading>
			<LoadableContent isLoading={state.isLoading}>
				<Grid container spacing={2} sx={{ p: 2, width: "100%" }}>
					<Grid item xs={12} sm={4}>
						<Paper>
							<Box p={2}>
								<Typography variant="h6" gutterBottom>Delivered Today</Typography>
								<Typography variant="h3" gutterBottom>{state.deliveredToday}</Typography>
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper>
							<Box p={2}>
								<Typography variant="h6" gutterBottom>Delivered This Week</Typography>
								<Typography variant="h3" gutterBottom>{state.deliveredWeek}</Typography>
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper>
							<Box p={2}>
								<Typography variant="h6" gutterBottom>Delivered This Month</Typography>
								<Typography variant="h3" gutterBottom>{state.deliveredMonth}</Typography>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</LoadableContent>
		</Box>
	);
}