import React from "react";
import {
	Route,
	Routes,
} from "react-router-dom";
import MissionControl from "../../MissionControl/MissionControl";
import CourierPerformance from "../../CourierPerformance/CourierPerformance";
import Summary from "../../Summary/Summary";
import NotificationChecker from "../../NotificationChecker/NotificationChecker";
import StatusChecker from "../../StatusChecker/StatusChecker";
import TrackingChecker from "../../TrackingChecker/TrackingChecker";
import CourierChecker from "../../CourierChecker/CourierChecker";
import EmailChecker from "../../EmailChecker/EmailChecker";
import Despatch from "../../Despatch/Despatch";
import CollectionChecker from "../../CollectionChecker/CollectionChecker";
import DeliverySLAChecker from "../../DeliverySLAChecker/DeliverySLAChecker";
import OrderRules from "../../OrderRules/OrderRules";
import OrderRuleDetail from "../../OrderRuleDetail/OrderRuleDetail";
import SellerConfiguration from "../../SellerConfiguration/SellerConfiguration";
import CourierConfiguration from "../../CourierConfiguration/CourierConfiguration";
import WarehouseConfiguration from "../../WarehouseConfiguration/WarehouseConfiguration";
import WarehouseDetail from "../../WarehouseDetail/WarehouseDetail";
import WarehouseCreate from "../../WarehouseCreate/WarehouseCreate";
import SellerView from "../../SellerView/SellerView";
import FinanceSellerStatus from "../../FinanceSellerStatus/FinanceSellerStatus";
import FinanceShippingFulfilment from "../../FinanceShippingFulfilment/FinanceShippingFulfilment";
import FinanceVAS from "../../FinanceVAS/FinanceVAS";
import ShippingPricing from "../../ShippingPricing/ShippingPricing";
import Debug from "../../DebugView/DebugView";
import Error from "../../ErrorPage/ErrorPage";
import SellerCreate from "../../SellerCreate/SellerCreate";
import QueueLatency from "../../QueueLatency/QueueLatency";
import ErrorList from "../../ErrorList/ErrorList";

export default function MainPanel() {

	return (
		<Routes>
			<Route exact path="/" element={<MissionControl />} />
			<Route exact path="/courier-performance" element={<CourierPerformance />} />
			<Route exact path="/summary" element={<Summary />} />
			<Route exact path="/notification-checker" element={<NotificationChecker />} />
			<Route exact path="/status-checker" element={<StatusChecker />} />
			<Route exact path="/tracking-checker" element={<TrackingChecker />} />
			<Route exact path="/courier-checker" element={<CourierChecker />} />
			<Route exact path="/despatch-checker" element={<Despatch />} />
			<Route exact path="/collection-checker" element={<CollectionChecker />} />
			<Route exact path="/delivery-sla-checker" element={<DeliverySLAChecker />} />
			<Route exact path="/order-rules" element={<OrderRules />} />
			<Route exact path="/rule-detail" element={<OrderRuleDetail />} />
			<Route exact path="/seller-configuration" element={<SellerConfiguration />} />
			<Route exact path="/courier-configuration" element={<CourierConfiguration />} />
			<Route exact path="/warehouse-configuration" element={<WarehouseConfiguration />} />
			<Route exact path="/warehouse-detail" element={<WarehouseDetail />} />
			<Route exact path="/warehouse-update" element={<WarehouseCreate />}/>
			<Route exact path="/warehouse-create" element={<WarehouseCreate />}/>
			<Route exact path="/seller-view/:id" element={<SellerView />} />
			<Route exact path="/seller-create" element={<SellerCreate />} />
			<Route exact path="/seller-edit" element={<SellerCreate />} />
			<Route exact path="/finance-seller-status" element={<FinanceSellerStatus />} />
			<Route exact path="/finance-shipping-fulfilment" element={<FinanceShippingFulfilment />} />
			<Route exact path="/finance-vas" element={<FinanceVAS />} />
			<Route exact path="/shipping-pricing" element={<ShippingPricing />} />
			<Route exact path="/queue-latency" element={<QueueLatency />} />
			<Route exact path="/email-checker" element={<EmailChecker />} />
			<Route exact path="/error-list" element={<ErrorList />} />
			<Route exact path="/debug" element={<Debug />} />

			<Route path="/*" element={<Error />} />
		</Routes>
	);
}   
