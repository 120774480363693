import React, {
	useState,
	useEffect,
	useCallback,
} from "react";
import {
	Link as RouterLink,
	useLocation
} from "react-router-dom";
import "./LeftHandMenu.scss";
import PushPinIcon from "@mui/icons-material/PushPin";
import CancelIcon from "@mui/icons-material/Cancel";
import Home from "@mui/icons-material/Language";
import CourierPerformance from "@mui/icons-material/BusAlert";
import Summary from "@mui/icons-material/MonitorHeart";
import NotificationChecker from "@mui/icons-material/LowPriority";
import StatusChecker from "@mui/icons-material/FactCheck";
import TrackingChecker from "@mui/icons-material/TravelExplore";
import CourierChecker from "@mui/icons-material/DoubleArrow";
import DespatchChecker from "@mui/icons-material/CompareArrows";
import CollectionChecker from "@mui/icons-material/AssignmentTurnedIn";
import DeliverySLAChecker from "@mui/icons-material/MapsHomeWork";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import OrderRules from "@mui/icons-material/Gavel";
import SellerConfiguration from "@mui/icons-material/PersonSearch";
import CourierConfiguration from "@mui/icons-material/PersonPinCircle";
import WarehouseConfiguration from "@mui/icons-material/PrecisionManufacturing";
import FinanceSellerStatus from "@mui/icons-material/ManageAccounts";
import FinanceShippingFulfilment from "@mui/icons-material/LocalShipping";
import FinanceVAS from "@mui/icons-material/MiscellaneousServices";
import ShippingPricing from "@mui/icons-material/PriceChange";
import Debug from "@mui/icons-material/Adb";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import {
	IconButton,
	Divider,
	ListItem,
	ListItemButton,
	List,
	ListItemIcon,
	ListItemText,
	Typography,
	Link,
	Box
} from "@mui/material";
import LightTooltip from "../LightTooltip";
import { getKeyValue } from "../../../utils/object";
import debounce from "lodash/debounce";

export default function LeftHandMenu({
	onHandleDrawer,
	isDrawerVisible,
	drawerWidth
}) {

	const [isHovering, setIsHovering] = useState(false);
	const to = useLocation().pathname;
	const duration = 100;

	const openedMixin = (theme) => ({
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.easeInOut,
			duration: duration,
		}),
		overflowX: "hidden",
	});
 
	const closedMixin = (theme) => ({
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.easeInOut,
			duration: duration,
		}),
		overflowX: "hidden",
		width: `calc(${theme.spacing(7)} + 1px)`,
		[theme.breakpoints.up("sm")]: {
			width: `calc(${theme.spacing(8)} + 1px)`,
		},
	});

	const DrawerHeader = styled("div")(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	}));

	const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
		({ theme, open }) => ({
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap",
			boxSizing: "border-box",
			...(open && {
				...openedMixin(theme),
				"& .MuiDrawer-paper": openedMixin(theme),
			}),
			...(!open && {
				...closedMixin(theme),
				"& .MuiDrawer-paper": closedMixin(theme),
			}),
		})
	);
	
	const handleDrawerClose = () => {
		onHandleDrawer(false);
	};

	const handlePinIcon = () => {
		onHandleDrawer(true);
		setIsHovering(false);
	};

	const PinIcon = () => (
		isDrawerVisible ? <PushPinIcon/> : <CancelIcon/>
	);

	useEffect(() => {
		onHandleDrawer(isDrawerVisible);
	}, [isDrawerVisible]);
	
	const handleMouseEnter = () => {
		if (!isDrawerVisible) {
			setIsHovering(true);
			onHandleDrawer(true);
		}
	};

	const handleMouseLeave = useCallback(
		debounce(() => {
			if (isHovering) {
				setIsHovering(false);
				onHandleDrawer(false);
			}
		}, duration),
		[isHovering, onHandleDrawer]
	);

	function addId(arr) {
		return arr.map(function (obj, index) {
			return Object.assign({}, obj, { id: index });
		});
	}

	const mainMenu = [
		{
			"title": "Mission Control",
			"link": "/",
			"icon": <Home />,
		},
		{
			"title": "Summary",
			"link": "/summary",
			"icon": <Summary />,
		},
		{
			"title": "Courier Performance",
			"link": "/courier-performance",
			"icon": <CourierPerformance />,
		},		
		{
			"title": "Notification Checker",
			"link": "/notification-checker",
			"icon": <NotificationChecker />,
		},
		{
			"title": "Status Checker",
			"link": "/status-checker",
			"icon": <StatusChecker />,
		},
		{
			"title": "Tracking Checker",
			"link": "/tracking-checker",
			"icon": <TrackingChecker />,
		},
		{
			"title": "Courier Checker",
			"link": "/courier-checker",
			"icon": <CourierChecker />,
		},
		{
			"title": "Despatch Checker",
			"link": "/despatch-checker",
			"icon": <DespatchChecker />,
		},
		{
			"title": "Collection Checker",
			"link": "/collection-checker",
			"icon": <CollectionChecker />,
		},
		{
			"title": "Delivery SLA Checker",
			"link": "/delivery-sla-checker",
			"icon": <DeliverySLAChecker />,
		},
		{
			"title": "Email Checker",
			"link": "/email-checker",
			"icon": <MarkEmailReadIcon/>,
		},
		{
			"title": "Order Rules",
			"link": "/order-rules",
			"icon": <OrderRules />,
		},		
		{
			"title": "Seller Configuration",
			"link": "/seller-configuration",
			"icon": <SellerConfiguration />,
		},
		{
			"title": "Courier Configuration",
			"link": "/courier-configuration",
			"icon": <CourierConfiguration />,
		},
		{
			"title": "Warehouse Configuration",
			"link": "/warehouse-configuration",
			"icon": <WarehouseConfiguration />,
		},			
		{
			"title": "Finance - Seller Status",
			"link": "/finance-seller-status",
			"icon": <FinanceSellerStatus />,
		},		
		{
			"title": "Finance - Shipping & Fulfilment",
			"link": "/finance-shipping-fulfilment",
			"icon": <FinanceShippingFulfilment />,
		},
		{
			"title": "Finance - VAS",
			"link": "/finance-vas",
			"icon": <FinanceVAS />,
		},
		{
			"title": "Shipping Pricing",
			"link": "/shipping-pricing",
			"icon": <ShippingPricing />,
		},
		{
			"title": "Queue Latency",
			"link": "/queue-latency",
			"icon": <HourglassBottomIcon/>,
		},
		{
			"title": "Error List",
			"link": "/error-list",
			"icon": <AnnouncementIcon/>,
		},
		{
			"title": "Debug Token",
			"link": "/debug",
			"icon": <Debug />,
		},
	];

	const menuItemsObjID = addId(mainMenu);
	const mainList = menuItemsObjID.filter(obj => obj.title !== "Debug Information");
	const debugList = menuItemsObjID.filter(obj => obj.title === "Debug Information");

	useEffect(() => {
		const pageTitle = getKeyValue(mainMenu, "link", to, "title");
		document.title = pageTitle ? `${pageTitle} - Bezos Ops Portal` : "Bezos Ops Portal";
	}, [to]);
	
	const ListBox = ({ list }) => {
		return (
			<List>
				{list.map((item) => {
					let listItemProps = {
						key: item.id,
						sx: { display: "block" },
						target: "_self",
					};

					if (item.link.includes("https")) {
						listItemProps.component = Link;
						listItemProps.href = `${item.link}`;
					} else {
						listItemProps.component = RouterLink;
						listItemProps.to = `${item.link}`;
					}

					return (
						<ListItem
							key="99"
							disablePadding
							{...listItemProps}
						>

							<ListItemButton
								sx={{
									justifyContent: isDrawerVisible ? "initial" : "center",
									px: 2.5,
									height: 35,
									boxSizing: "border-box"
								}}
								className={to === `${item.link}` ? "page-match" : null}
							>

								<LightTooltip
									title={item.title}
									placement="right-start"
									disableHoverListener={isDrawerVisible ? true : false}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: isDrawerVisible ? 3 : "auto",
											justifyContent: "center",
											color: to === `${item.link}` ? "#FFF" : "#666",
										}}
									>
										{item.icon}
									</ListItemIcon>
								</LightTooltip>

								<ListItemText
									primary={item.title}
									sx={{
										opacity: isDrawerVisible ? 1 : 0,
										color: to === `${item.link}` ? "#FFF" : "#666",
									}}
								/>

							</ListItemButton>

						</ListItem>

					);
				})}
			</List>
		);
	};

	return (
		<Box id="side-nav">
			<Drawer 
				variant="permanent" 
				open={isDrawerVisible}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>

				<DrawerHeader className="side-nav-header">
					<Typography sx={{ ml: 2 }} >Navigation</Typography>
					<IconButton onClick={isHovering ? handlePinIcon : handleDrawerClose}>
						{isHovering ? <PinIcon/> : <CancelIcon/>}
					</IconButton>
				</DrawerHeader>

				<Divider sx={{ mb: 1 }} />
				<ListBox list={mainList} />
				<Divider sx={{ mt: 1, mb: 1 }} />
				<ListBox list={debugList} />

			</Drawer>
		</Box>
	);
}
