const jsonContentTypeOptions = { headers: { "Content-Type": "application/json" } };
const formDataOptions = { headers: { Accept: "multipart/form-data" } };

export default class DataProvider {
	constructor(requestProvider) {
		this.requestProvider = requestProvider;
	}

	getIfIDIsValid = async (id, url, options) => {
		if (!id) return;
		return await this.requestProvider.get(url, options);
	};

	postIfIDIsValid = async (id, url, value, options) => {
		if (!id) return;
		return this.requestProvider.post(url, value, options);
	};

	putIfIDIsValid = async (id, url, value, options) => {
		if (!id) return;
		return this.requestProvider.put(url, value, options);
	};

	deleteIfIDIsValid = async (id, url, options) => {
		if (!id) return;
		return this.requestProvider.delete(url, options);
	};

	// Send Email Component for Checker Pages (Notification Checker, Collection Checker)
	sendEmailCheckers = async (url, params, postBodyData) =>
		this.requestProvider.post(url + params, postBodyData, formDataOptions);

	// All CSV Download Paginated requests (All Finance Pages, Shipping Pricing)
	getPaginatedTableData = async (id, url, signal, params) => {
		return this.getIfIDIsValid(id, url, { signal: signal, params: params });
	};
	
	// Shipping Pricing to Bulk Upload Pricing
	uploadFile = async (url, postBodyData) =>
		this.requestProvider.post(url, postBodyData, formDataOptions);

	// Seller Detail
	getSeller = async (sellerID) => {
		const url  = "/v1/seller-detail?";
		const queryParams = new URLSearchParams({seller_id: sellerID});
		return this.getIfIDIsValid(sellerID, url + queryParams);
	};

	// All Sellers
	getSellers = async () =>
		this.requestProvider.get("/v1/sellers");

	// Mission Control
	getPerformanceSummary = async () =>
		this.requestProvider.get("/v1/performance-summary");

	getCourierPerformance = async (params) => {
		const url = "/v1/courier-performance?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	// Order Rules
	getOrderRules = async () =>
		this.requestProvider.get("/v1/order-rules");

	createOrderRule = async ( name, actionID, conditions ) => {
		const url = "/v1/order-rule";
		const body = {
			"name": name,
			"action": actionID,
			"conditions": conditions,
		}
		return this.requestProvider.post(url, body, formDataOptions);
	}

	deleteOrderRule = async (id) =>
		this.deleteIfIDIsValid(id, "/v1/order-rule/" + encodeURIComponent(id), jsonContentTypeOptions);

	getOrderRulesRefData = async () =>
		this.requestProvider.get("/v1/order-rules-refdata");

	getOrderRuleToSellerMappings = async (params) => {
		const url = "/v1/order-rule-clients?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	updateOrderRuleToSellerMappings = async (id, clientIds) => {
		const url = `/v1/order-rule/${encodeURIComponent(id)}/mappings`;
		const body = {
			"client_ids": clientIds
		}
		return this.putIfIDIsValid(id, url, body, jsonContentTypeOptions);
	};

	// Summary
	getTotals = async (params) => {
		const url = "/v1/sync/totals?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getUnsyncedOrders = async (params) => {
		const url = "/v1/sync/unsynced/summary?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getWarehouseOrders = async (params) => {
		const url = "/v1/sync/warehouse_orders/summary?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	// Notification Checker
	getOnBackOrder = async (params) => {
		const url = "/v1/on-back-order?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getOnBackOrderStats = async (params) => {
		const url = "/v1/on-back-order/stats?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getOrdersFraudRisk = async (params) => {
		const url = "/v1/fraud-risk/stats?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	// Status Checker
	getBezosNotWarehouse = async (params) => {
		const url = "/v1/sync/bezos-not-warehouse?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getWarehouseNotBezos = async (params) => {
		const url = "/v1/sync/warehouse-not-bezos?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getStatusChecker = async (params) => {
		const url = "/v1/sync/status-checker?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getOrdersSyncUnintended = async (params) => {
		const url = "/v1/sync/orders-sync-unintended?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	// Tracking Checker 
	getTrackingDetails = async () => {
		const url = "/v1/tracking/detail";
		return this.requestProvider.get(url);
	};

	// Courier Checker
	getCourierDetails = async (params) => {
		const url = "/v1/courier/detail?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	// Courier Configuration
	getCourierServiceToAreas = async () => 
		this.requestProvider.get("/v1/bezos-couriers/service-to-area");

	deleteCourierServiceToArea = async (id, courierServiceID, areaID) => {
		const url = "/v1/bezos-couriers/service-to-area";
		const body = {
			id: id,
			courier_service_id: courierServiceID,
			area_id: areaID
		};
		// add config to use JSON body object for delete requests
		const config = {
			method: 'delete',
			url: url,
			data: body,
			...jsonContentTypeOptions,
		};
		return this.requestProvider.request(config);
	};
	
	putCourierServiceToArea = async (id, courierServiceID, areaID) => {
		const url = "/v1/bezos-couriers/service-to-area";
		const body = {
			id: id,
			courier_service_id: courierServiceID,
			area_id: areaID
		}
		return this.putIfIDIsValid(id, url, body, jsonContentTypeOptions);
	};

	postCourierServiceToArea = async (id, courierServiceID, areaID) => {
		const url = "/v1/bezos-couriers/service-to-area";
		const body = {
			id: id,
			courier_service_id: courierServiceID,
			area_id: areaID
		}
		return this.postIfIDIsValid(id, url, body, jsonContentTypeOptions);
	};

	getBezosCouriers = async () => 
		this.requestProvider.get("/v1/bezos-couriers");

	getServiceAreas = async () => 
		this.requestProvider.get("/v1/courier-service-areas");

	// Warehouse Configuration
	getWarehouseConfigurations = async (params) => {
		const url = "/v1/warehouses?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	updateWarehouse = async (id, body) => {
		const url = "/v1/warehouse";
		return this.putIfIDIsValid(id, url, body, jsonContentTypeOptions);
	}

	createWarehouse = async (body) => {
		const url = "/v1/warehouse";
		return this.requestProvider.post(url, body, formDataOptions);
	}

	getWarehouseCredentials = async (params) => {
		const url = "/v1/warehouse-credentials?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getPlatformIDs = async () => 
		this.requestProvider.get("/v1/platforms");

	// Despatch Checker
	getDespatchDetails = async (params) => {
		const url = "/checkers/despatch?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getDespatchTotals = async (params) => {
		const url = "/v1/despatch-totals?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams, jsonContentTypeOptions);
	};

	getWarehouses = async () =>
		this.requestProvider.get("/operations/v1/warehouses");

	getDespatchTicket = async (ticket) => {
		const url = "/tickets/tickets"
		this.requestProvider.post(url, JSON.stringify(ticket), jsonContentTypeOptions);
	};

	getContactData = async (sellerID) => {
		const url = "/tickets/contacts?";
		const queryParams = new URLSearchParams({ accountName: sellerID });
		return this.getIfIDIsValid(sellerID, url + queryParams);
	};

	// Collection Checker
	getCollectionData = async (params) => {
		const url = "/v1/collection/?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getCollectionStats = async (params) => {
		const url = "/v1/collection/stats?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getWarehouseResponse = async () =>
		this.requestProvider.get("/v1/warehouses");

	// Delivery SLA Checker
	getDeliverySLA = async (params) => {
		const url = "/v1/delivery-sla?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};

	getDeliverySLATypes = async () =>
		this.requestProvider.get("/v1/delivery-sla-email-types");

	sendEmailDeliverySLA = async (order, params, postBodyData) => {
		const url = "/v1/delivery-sla/email?";
		const queryParams = new URLSearchParams(params);
		return this.postIfIDIsValid(order, url + queryParams, postBodyData, formDataOptions);
	};

	createSeller = async (seller) => {
		const url = "/v1/seller";
		return this.requestProvider.post(url, JSON.stringify(seller), jsonContentTypeOptions);
	};

	updateSeller = async (sellerID, seller) => {
		const params = new URLSearchParams({ seller_id: sellerID });
		return this.putIfIDIsValid(sellerID, "/v1/seller?" + params, seller, jsonContentTypeOptions);
	};

	getSellerStatuses = async () =>
		this.requestProvider.get("/v1/seller-statuses");

	// Seller Status
	getSellerStatusHistory = async (sellerID, params) => {
		const url = "/v1/seller-status-history?";
		const queryParams = new URLSearchParams(params);
		return this.getIfIDIsValid(sellerID, url + queryParams);
	};

	updateSellerStatus = async (sellerID, sellerStatus) => {
		const body = {
			seller_id: sellerID,
			status_name: sellerStatus
		};
		return this.putIfIDIsValid(sellerID, "/v1/seller-status", body, jsonContentTypeOptions);
	};

	getQueueStatistics = async () =>
		this.requestProvider.get("/v1/queues/stats");

	getPlatformErrors = async() => 
		this.requestProvider.get("/v1/platform/errors");

	getPlatformWarnings = async() => 
		this.requestProvider.get("/v1/platform/warnings");

	getDeliveredEvents = async (params) => {
		const url = "/v1/sendgridevents/delivered?";
		const queryParams = new URLSearchParams(params);
		return this.requestProvider.get(url + queryParams);
	};
}
